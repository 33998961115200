const DOCUMENT_VALID_SILE_SIZE = 50e6;
export const MAX_PASSWORD_LENGTH = 68;

export const MAX_HOURS_PER_WEEK = 168;
export const MAX_DEFAULT_TEXTAREA_LENGTH = 1500;
export const TEXT_EDITOR_DEFAULT_MAX_LENGTH = 2500;
export const MAX_ENGAGEMENT_TEXTAREA_LENGTH = TEXT_EDITOR_DEFAULT_MAX_LENGTH;
export const MAX_APPLY_MESSAGE_TEXTAREA_LENGTH = TEXT_EDITOR_DEFAULT_MAX_LENGTH;
export const MAX_OPPORTUNITY_TEXTAREA_LENGTH = 3000;

export enum ValidationError {
  default = 'This field is required',
  number = 'Must be a number',
  integer = 'Must be an integer',
  positive = 'Must be a positive',
  date = 'Must be a valid date',
  dateStartTomorrow = 'Start date should be after today',
  dateEndBeforeDateStart = "End date can't be before start date",
  outdatedEndDateError = 'The Engagement end day cannot be in the past',
  password = 'Spaces are not allowed for passwords.',
  confirmPassword = 'Passwords must match',
  email = 'Must be a valid email address.',
  url = 'Must be a valid url address.',
  phone = 'The phone number entered is not valid.',
  firstNameLength = 'First Name must be at most 30 characters',
  lastNameLength = 'Last Name must be at most 30 characters',
  passwordMinLength = `Password must be more than 6 characters`,
  passwordMaxLength = `Password must be less than 68 characters`,
  maxFieldLength = 'You cannot use more than',
  requiredFile = 'Please select a file',
  moreThanZero = 'Must be more than 0',
  mustAccepted = 'Must be accepted',
}

export enum FileType {
  'jpg' = '.jpg',
  'jpeg' = '.jpeg',
  'png' = '.png',
  'gif' = '.gif',
  'tiff' = '.tiff',
  'csv' = '.csv',
  'doc' = '.doc',
  'docx' = '.docx',
  'xls' = '.xls',
  'xlsx' = '.xlsx',
  'pdf' = '.pdf',
  'psd' = '.psd',
  'eps' = '.eps',
  'ai' = '.ai',
  'indd' = '.indd',
  'raw' = '.raw',
  'mp4' = '.mp4',
  'webm' = '.webm',
  'mov' = '.mov',
}

export enum ValidFileSize {
  avatarPicture = 5e6,
  logo = 5e6,
  file = 50e6,
  'logo.source' = 5e6,
  'file.source' = DOCUMENT_VALID_SILE_SIZE,
  'fluShot.file.source' = DOCUMENT_VALID_SILE_SIZE,
  'mmrVax.file.source' = DOCUMENT_VALID_SILE_SIZE,
  'covid19Vax.file.source' = DOCUMENT_VALID_SILE_SIZE,
  'multipanelDrugScreen.file.source' = DOCUMENT_VALID_SILE_SIZE,
  'backgroundCheck.file.source' = DOCUMENT_VALID_SILE_SIZE,
  'tuberculosisVax.file.source' = DOCUMENT_VALID_SILE_SIZE,
}
