import { useState, useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { isEmpty } from 'ramda';
import { NumericFormat } from 'react-number-format';

import { removeNonCurrencyChars } from 'src/utils/string';

type CurrencyTextFieldProps = TextFieldProps & {
  initialValue?: string;
  onValueChange?: (value: string) => void;
};

const MAXIMUM_FRACTION_DIGITS = 2;

const CurrencyTextField: React.FC<CurrencyTextFieldProps> = props => {
  const { initialValue = '', value, onBlur, onValueChange, defaultValue, type, ...restProps } = props;

  const [inputValue, setInputValue] = useState<string>(initialValue);

  // This fix pay rate field initializing in the engagement form.
  useEffect(() => {
    setInputValue(prevValue => {
      if (prevValue?.length === 0 && initialValue?.length > 0) {
        return initialValue;
      }
      return prevValue;
    });
  }, [initialValue]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
    if (!inputValue) return;
    const formattedValue = Number(inputValue).toFixed(MAXIMUM_FRACTION_DIGITS);
    setInputValue(formattedValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = removeNonCurrencyChars(event.target.value);

    const [, decimal] = currentValue.split('.');

    if (decimal?.length > MAXIMUM_FRACTION_DIGITS) return;
    setInputValue(currentValue);

    const formattedValue = Number(currentValue).toFixed(MAXIMUM_FRACTION_DIGITS);
    const changedValue = isEmpty(currentValue) ? '' : formattedValue;
    onValueChange(changedValue);
  };

  return (
    <NumericFormat
      customInput={TextField}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScalereact-nu
      allowNegative={false}
      value={value ? String(value) : inputValue}
      onBlur={handleBlur}
      onChange={handleChange}
      {...restProps}
    />
  );
};

export default CurrencyTextField;
