import { Avatar, Typography, TypographyProps as MuiTypographyProps, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { isNil } from 'ramda';

import Box from 'src/components/Box';
import RevuudApproved from 'src/components/RevuudApproved';
import TypographyNoWrap from 'src/components/TypographyNoWrap';

import styles from './styles';

type TalentPersonalInfoProps = {
  avatar: string;
  city?: string | null;
  state?: string | null;
  name: string;
  TypographyProps?: MuiTypographyProps;
  to?: string;
  isRevuudApproved?: boolean;
};

const TalentPersonalInfo: React.FC<TalentPersonalInfoProps> = props => {
  const { avatar, city = null, state = null, name, to, TypographyProps = {}, isRevuudApproved = false } = props;

  const renderTitle = () => {
    const Name = (
      <TypographyNoWrap variant="button" tooltipVariant="light" {...TypographyProps}>
        {name}
      </TypographyNoWrap>
    );

    const TitleText = isNil(to) ? (
      Name
    ) : (
      <MuiLink sx={styles.link} component={Link} to={to} underline="hover" target="_blank">
        {Name}
      </MuiLink>
    );

    return (
      <Box sx={styles.title}>
        {TitleText}
        {isRevuudApproved && <RevuudApproved />}
      </Box>
    );
  };

  return (
    <Box sx={styles.root}>
      <Avatar sx={styles.avatar} alt={name} src={avatar} variant="square">
        {name.at(0)}
      </Avatar>
      <Box sx={styles.content}>
        {renderTitle()}
        {city && (
          <Typography variant="body2">
            {city}, {state}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TalentPersonalInfo;
