import * as yup from 'yup';
import { EditorState } from 'draft-js';

import { GetMaxLengthTest } from 'src/forms/customRules';
import { TEXT_EDITOR_DEFAULT_MAX_LENGTH } from 'src/enums/Validation';
import { convertFromHtmlFormat } from 'src/utils/textEditor';

export type TalentQualificationReviewFormValues = {
  managerComment: EditorState;
};

const validationFields = {
  managerComment: yup.mixed().test(GetMaxLengthTest(TEXT_EDITOR_DEFAULT_MAX_LENGTH)).default(EditorState.createEmpty()),
};

export const validationSchema = yup.object(validationFields);

export const calcInitialValues = (managerComment: string | null): TalentQualificationReviewFormValues => {
  if (managerComment) {
    return {
      managerComment: convertFromHtmlFormat(managerComment),
    };
  }

  return validationSchema.getDefault();
};
