import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { split, range } from 'ramda';
import {
  addDays,
  format,
  isThisYear,
  isValid,
  startOfWeek,
  parseISO,
  formatISO,
  isToday,
  isPast,
  subWeeks,
  subMonths,
  startOfYear,
  endOfYear,
  eachYearOfInterval,
  compareDesc,
  lastDayOfYear,
  isWithinInterval,
  isDate,
  isBefore,
  subDays,
  sub,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import enUSLocale from 'date-fns/locale/en-US';

type ExpirationDateRangePair = [expirationRangeBegin: Date, expirationRangeEnd: Date];

type FormatTimeOption = {
  padStartHours?: number;
  isPrefixVisible?: boolean;
  isOnlyNegativePrefixVisible?: boolean;
};

const DAYS_IN_WEEK = 7;
const DEFAULT_EXPIRATION_MONTH_COUNT = 1;

export const MINUTE_SECONDS = 60;
export const HOUR_SECONDS = 3600;
export const DAY_SECONDS = 86400;

export const MAX_HOURS_PER_DAY = 23;

export const TODAY = new Date();
export const YESTERDAY = sub(new Date(), { days: 1 });
export const DATEPICKER_MIN_DATE = new Date('1940');
export const DATEPICKER_MAX_DATE = new Date('2099');
export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'MM/dd/yyyy h:mm aaaa';
export const DEFAULT_DATE_FORMAT_SHORT_YEAR = 'MM/dd/yy';
export const DEFAULT_DATE_TIME_FORMAT_SHORT_YEAR = 'MM/dd/yy h:mm aaaa';
export const LONG_MONTH_FORMAT = 'MMMM d, yyyy';
export const SHORT_MONTH_FORMAT = 'MMM d, yyyy';
export const WEEKDAY_FORMAT_TWO_SYMBOLS = 'EEEEEE';
export const WEEKDAY_FORMAT_THREE_SYMBOLS = 'EEE';
export const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
export const YEAR_FORMAT = 'yyyy';
export const PERIOD_SEPARATOR = ' – ';

export const START_DATE_ENGAGEMENT_CREATION = subWeeks(TODAY, 1);

export const CHAT_SEPARATOR_DATE_FORMAT = 'MMM d, yyyy kk:mmaa';
export const CHAT_LAST_MESSAGE_DATE_FORMAT = 'MMM d, yyyy';
export const CHAT_MESSAGE_TIME_DATE_FORMAT = 'kk:mmaa';

export const TIME_SHEET_DATE_FORMAT = 'EEE, MMMM d';
export const TIME_SHEET_LONG_DATE_FORMAT = 'EEEE, PP';

export class AdapterDateFnsWithYearsReverse extends AdapterDateFns {
  getYearRange = (start: Date, end: Date): Date[] => {
    const yearRange = eachYearOfInterval({
      start: startOfYear(start),
      end: endOfYear(end),
    });

    return yearRange.reverse();
  };
}

export const isValidDatePeriod = (value: string): boolean => {
  const minValidPeriodDate = new Date(0);
  const maxValidPeriodDate = TODAY;

  const [firstDate, lastDate] = split(PERIOD_SEPARATOR, value);
  const beginDate = new Date(firstDate);
  const endDate = new Date(lastDate);

  const validations = [
    isValid(beginDate),
    isValid(endDate),
    beginDate <= endDate,
    minValidPeriodDate <= beginDate,
    endDate <= maxValidPeriodDate,
  ];

  return validations.every(element => element);
};

export const isValidSingleYear = (value: string): boolean => {
  const maxValidDate = TODAY;
  const year = new Date(value);

  return isValid(year) && maxValidDate >= year;
};

export const isValidDatePickerDate = (date: unknown): boolean => {
  if (!isDate(date)) return false;

  try {
    const isWithinDatePickerInterval = isWithinInterval(date as Date, {
      start: DATEPICKER_MIN_DATE,
      end: lastDayOfYear(DATEPICKER_MAX_DATE),
    });

    return isWithinDatePickerInterval;
  } catch (error) {
    return false;
  }
};

export const getYearByDate = (date: string): number => {
  const dateISO = parseISO(date);
  return dateISO.getFullYear();
};

export const getYearMonthByDate = (date: string): string => {
  const dateISO = parseISO(date);
  const month = dateISO.toLocaleString('default', { month: 'long' });
  return `${month} ${dateISO.getFullYear()}`;
};

export const getTimeByDate = (date: string | Date): string => {
  return format(new Date(date), 'kk:mm');
};

export const getChatMessageSeparatorDate = (date: string): string => {
  const currentDate = new Date(date);
  const isCurrentDateToday = isToday(currentDate);
  const isCurrentYear = isThisYear(currentDate);

  const todayMessageOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
  const beforeTodayMessageOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'short',
    ...(!isCurrentYear && { year: 'numeric' }),
  };

  const options = isCurrentDateToday ? todayMessageOptions : beforeTodayMessageOptions;

  return new Intl.DateTimeFormat(undefined, options).format(currentDate);
};

export const getChatLastMessageDate = (date: string, isShort?: boolean): string => {
  const currentDate = new Date(date);
  const isCurrentDateToday = isToday(currentDate);

  const todayMessageOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
  const beforeTodayMessageOptions: Intl.DateTimeFormatOptions = {
    ...{ day: 'numeric', month: 'short' },
    ...(isShort ? {} : todayMessageOptions),
  };

  const options = isCurrentDateToday ? todayMessageOptions : beforeTodayMessageOptions;

  return new Intl.DateTimeFormat(undefined, options).format(currentDate);
};

export const getDateByYear = (year: string): string => {
  const dateISO = parseISO(year);
  return format(dateISO, SERVER_DATE_FORMAT);
};

export const getLongMonthAndDate = (date: string): string => {
  const dateISO = parseISO(date);
  return format(dateISO, LONG_MONTH_FORMAT);
};

export const getShortMonthAndDate = (date: string): string => {
  const dateISO = parseISO(date);
  return format(dateISO, SHORT_MONTH_FORMAT);
};

export const getFullDate = (date: string, dateFormat: string = DEFAULT_DATE_FORMAT): string => {
  const dateISO = parseISO(date);
  return format(dateISO, dateFormat);
};

export const getFormattedDateString = (date: Date, dateFormat: string = DEFAULT_DATE_FORMAT): string => {
  return format(date, dateFormat);
};

export const getFullDateISO = (date: string): string => {
  const dateISO = parseISO(date);
  return formatISO(dateISO);
};

export const getTimeSheetDate = (date: string): string => {
  const dateISO = parseISO(date);
  return format(dateISO, TIME_SHEET_DATE_FORMAT);
};

export const getTimeSheetLongDate = (date: string): string => {
  const dateISO = parseISO(date);
  return format(dateISO, TIME_SHEET_LONG_DATE_FORMAT);
};

export const getWeekdayName = (date: Date, dateFormat: string = WEEKDAY_FORMAT_THREE_SYMBOLS): string =>
  format(date, dateFormat);

export const getShortWeekdayNames = (): string[] => {
  const firstWeekDay = startOfWeek(TODAY);
  return range(0, DAYS_IN_WEEK).map(weekday => getWeekdayName(addDays(firstWeekDay, weekday)));
};

export const getLocalTime = (): string => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  return new Intl.DateTimeFormat(undefined, options).format(TODAY);
};

export const getCurrentYear = (): string => format(TODAY, YEAR_FORMAT);
export const getNextYear = (): Date => {
  const currentYear = TODAY.getFullYear();
  const nextYear = currentYear + 1;

  return new Date(nextYear.toString());
};

export const isExpiredDate = (date: string): boolean => {
  const dateISO = parseISO(date);
  return isPast(dateISO);
};

export const getExpirationDateRange = (
  expirationDate: string,
  monthCount = DEFAULT_EXPIRATION_MONTH_COUNT,
): ExpirationDateRangePair => {
  const expirationDateISO = parseISO(expirationDate);
  return [subMonths(new Date(expirationDateISO), monthCount), new Date(expirationDateISO)];
};

export const isTodayInExpirationRange = (expirationDateRange: ExpirationDateRangePair): boolean => {
  const [expirationDateRangeBegin, expirationDateRangeEnd] = expirationDateRange;
  return TODAY >= expirationDateRangeBegin && TODAY < expirationDateRangeEnd;
};

export const convertDateToServerFormat = (date: Date): string => {
  return format(date, SERVER_DATE_FORMAT);
};

export const isFirstDateGreaterThanSecondDate = (firstDate: Date, secondDate: Date): boolean => {
  return compareDesc(firstDate, secondDate) === 1;
};

export const getYearToDateRange = (): { startDate: Date; endDate: Date } => {
  const currentYear = Number(getCurrentYear());
  return {
    startDate: new Date(currentYear, 0, 1),
    endDate: TODAY,
  };
};

export function getRolling12MonthsPeriod() {
  const today = new Date();
  const startDate = startOfMonth(subMonths(today, 11));
  const endDate = endOfMonth(today);

  return { startDate, endDate };
}

export const getDateRange = (dateRange: { startDate: Date; endDate: Date }): string => {
  const startDateISO = getDateByYear(dateRange.startDate.toISOString());
  const endDateISO = getDateByYear(dateRange.endDate.toISOString());
  const startDate = getFullDate(startDateISO);
  const endDate = getFullDate(endDateISO);

  return `${startDate} - ${endDate}`;
};

export const getTimeMinutes = (time: number): number => Math.floor((time % HOUR_SECONDS) / MINUTE_SECONDS);
export const getTimeHours = (time: number): number => Math.floor((time % DAY_SECONDS) / HOUR_SECONDS);
export const getTimeDays = (time: number): number => Math.floor(time / DAY_SECONDS);

export const formatTime = (decimalString: string, options: FormatTimeOption = {}): string => {
  const { padStartHours = 2, isPrefixVisible = false, isOnlyNegativePrefixVisible = false } = options;

  const [hours, minutes = ''] = decimalString.split('.');
  const paddedMinutes = minutes.padEnd(2, '0');
  const prepareMinutes = Math.floor((Number(paddedMinutes) * 60) / 100);

  let prefix = '';
  if (Number(decimalString) > 0 && !isOnlyNegativePrefixVisible) prefix = '+';
  if (Number(decimalString) < 0) prefix = '-';

  const formattedPrefix = isPrefixVisible ? prefix : '';
  const formattedHours = hours.replace('-', '').padStart(padStartHours, '0');
  const formattedMinutes = prepareMinutes.toString().padStart(2, '0');

  return `${formattedPrefix}${formattedHours}:${formattedMinutes}`;
};

export const isDateOlderThanDays = (date: string, daysLimit: number) => {
  const dateToCheck = parseISO(date);
  const limitDaysAgo = subDays(new Date(), daysLimit);

  return isBefore(dateToCheck, limitDaysAgo);
};

export {
  addDays,
  subDays,
  lastDayOfMonth,
  differenceInDays,
  endOfDay,
  isWithinInterval,
  parseISO,
  subMinutes,
  addSeconds,
  isSameSecond,
} from 'date-fns';

export { format as formatWithTimezone, utcToZonedTime } from 'date-fns-tz';
export { enUSLocale };
