import { Box, Button, Typography } from '@mui/material';

import { TalentOpportunityApplyInfo } from 'src/features/opportunities/types/talentOpportunityApply';
import { useModals } from 'src/hooks';

import styles from './styles';
import { TalentApplicationMessage } from './TalentApplicationMessage';

export type AppliedMessageModalContentProps = TalentOpportunityApplyInfo & { talentFullName: string };

const AppliedMessageModalContent: React.FunctionComponent<AppliedMessageModalContentProps> = props => {
  const { experience, skillsAndQualifications, applyMessage, talentFullName } = props;

  const { hideModal } = useModals();

  return (
    <Box sx={styles.modalWrapper}>
      <Typography variant="h3" mb={3}>
        {talentFullName || ''} Qualifications
      </Typography>

      <TalentApplicationMessage
        applyMessage={applyMessage}
        skillsAndQualifications={skillsAndQualifications}
        experience={experience}
      />

      <Button variant="outlined" onClick={() => hideModal()}>
        Close
      </Button>
    </Box>
  );
};

export default AppliedMessageModalContent;
