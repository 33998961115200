import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { EditorState } from 'draft-js';
import { LoadingButton } from '@mui/lab';

import { TalentApplicationMessage } from 'src/features/opportunities/details/components/ApplicantsTable/components/TalentApplicationMessage';
import { useModals, useSnackbar } from 'src/hooks';
import {
  TalentOpportunityApplyBase,
  TalentOpportunityApplyInfo,
} from 'src/features/opportunities/types/talentOpportunityApply';
import {
  TalentQualificationReviewFormValues,
  calcInitialValues,
  validationSchema,
} from 'src/forms/orgSide/talentReviewQualification';
import TextEditor from 'src/components/TextEditor';
import { TEXT_EDITOR_DEFAULT_MAX_LENGTH } from 'src/enums/Validation';
import { usePatchOpportunityTalentMutation } from 'src/services/OpportunityTalent';
import { convertToHtmlFormat, isEmptyTextEditor } from 'src/utils/textEditor';
import { ReactComponent as EditIcon } from 'src/assets/images/editIcon.svg';

type ApplicantQualificationReviewModalProps = TalentOpportunityApplyInfo &
  Pick<TalentOpportunityApplyBase, 'id' | 'managerComment'> & {
    talentFullName: string;
    onSaveManagerComment: () => void;
  };

const ApplicantQualificationReviewModal: React.FunctionComponent<ApplicantQualificationReviewModalProps> = props => {
  const {
    talentFullName,
    applyMessage,
    skillsAndQualifications,
    experience,
    managerComment,
    id,
    onSaveManagerComment,
  } = props;

  const [updateApply] = usePatchOpportunityTalentMutation();
  const { hideModal } = useModals();
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const [isEditingMode, setIsEditingMode] = useState(!managerComment);

  const handleFormSubmit = async (
    newFormValues: TalentQualificationReviewFormValues,
    formikHelpers: FormikHelpers<TalentQualificationReviewFormValues>,
  ) => {
    try {
      let newManagerComment: TalentOpportunityApplyBase['managerComment'] = null;
      if (!isEmptyTextEditor(newFormValues.managerComment)) {
        newManagerComment = convertToHtmlFormat(newFormValues.managerComment);
      }

      await updateApply({ applyId: id, newApplyData: { managerComment: newManagerComment } }).unwrap();
      formikHelpers.setSubmitting(false);
      enqueueSuccessSnackbar('Comment was saved');
      setIsEditingMode(false);
      onSaveManagerComment();
    } catch (err) {
      enqueueErrorSnackbar();
    }
  };

  const formik = useFormik<TalentQualificationReviewFormValues>({
    initialValues: calcInitialValues(managerComment),
    onSubmit: handleFormSubmit,
    validationSchema,
  });

  const handleManagerCommentChange = (newEditorState: EditorState) => {
    formik.setFieldValue('managerComment', newEditorState);
  };

  return (
    <Box
      sx={{
        width: '600px',
        padding: 3,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h3" mb={3}>
          {talentFullName || ''} Qualifications
        </Typography>
        <Box sx={{ borderBottom: theme => `1px solid ${theme.palette.black10}`, mb: 3, pb: isEditingMode ? 0 : 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            <Typography fontWeight="600" mb={isEditingMode ? 0 : 1}>
              Opportunity Manager Comments
            </Typography>
            {!isEditingMode && (
              <Button variant="text" startIcon={<EditIcon />} size="small" onClick={() => setIsEditingMode(true)}>
                Edit
              </Button>
            )}
          </Box>

          <TextEditor
            value={formik.values.managerComment}
            onChange={handleManagerCommentChange}
            error={formik.touched.managerComment && formik.errors.managerComment}
            maxValue={TEXT_EDITOR_DEFAULT_MAX_LENGTH}
            readOnly={!isEditingMode}
            usePredefinedMinHeight={isEditingMode}
            isCounterVisible={isEditingMode}
          />
        </Box>

        <TalentApplicationMessage
          applyMessage={applyMessage}
          skillsAndQualifications={skillsAndQualifications}
          experience={experience}
        />
        <LoadingButton
          loading={formik.isSubmitting}
          variant="contained"
          type="submit"
          sx={{ mr: 1 }}
          disabled={!isEditingMode || !formik.dirty}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={() => hideModal()}>
          Close
        </Button>
      </form>
    </Box>
  );
};

export default ApplicantQualificationReviewModal;
