import { CreateOrganizationFormDataToSubmit } from 'src/forms/createOrganization';
import { AccountType } from 'src/enums/AccountType';
import { Organization, OrganizationUserCount, OrganizationsCount } from 'src/types/resources/Organization';
import { SingleResponse, ListResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import Fetcher from 'src/utils/fetcher';

export type IndexParams = Partial<{
  page: number;
  ordering: string;
  isActive: boolean;
  accountType: AccountType;
  isHiddenOnSuperadminSide: boolean;
}>;

export type LoadUsersCountParams = {
  id: number;
  params?: {
    departmentId: number;
  };
};

export type ShowParams = {
  id: number;
};

export type UpdateParams = {
  id: number;
  params: Partial<Omit<Organization, 'logo'>> & {
    logo?: File | string | null;
  };
};

export default {
  index(params: IndexParams = { page: 1, ordering: '' }): Promise<ListResponse<Organization>> {
    const url = apiRoutes.superAdmin.organizationsPath();
    return Fetcher.get(url, params);
  },
  counts(params: Pick<IndexParams, 'accountType' | 'isActive' | 'isHiddenOnSuperadminSide'>) {
    return Fetcher.get<SingleResponse<OrganizationsCount>>(apiRoutes.superAdmin.organizationsCounts(), params);
  },
  show({ id }: ShowParams): Promise<SingleResponse<Organization>> {
    const url = apiRoutes.superAdmin.organizationPath(id);
    return Fetcher.get(url);
  },
  loadUsersCount({ id, params }: LoadUsersCountParams): Promise<SingleResponse<OrganizationUserCount>> {
    const url = apiRoutes.superAdmin.organizationUsersCountPath(id);
    return Fetcher.get(url, params);
  },
  create(params: CreateOrganizationFormDataToSubmit): Promise<SingleResponse<Organization>> {
    const url = apiRoutes.superAdmin.organizationsPath();
    return Fetcher.postFormData(url, params);
  },
  update({ id, params }: UpdateParams): Promise<SingleResponse<Organization>> {
    const url = apiRoutes.superAdmin.organizationPath(id);
    return Fetcher.putFormData(url, params);
  },
  partialUpdate({ id, params }: UpdateParams): Promise<SingleResponse<Organization>> {
    const url = apiRoutes.superAdmin.organizationPath(id);
    return Fetcher.partialUpdateFormData(url, params);
  },
};
