import React, { MouseEventHandler, useEffect, useRef } from 'react';
import { Dialog, DialogContent } from '@mui/material';

import { ModalLayout, ModalType } from 'src/enums/ModalType';
import useModals from 'src/hooks/useModals';
import useRouter from 'src/hooks/useRouter';
import { useScreen } from 'src/hooks';
import useHighOrderComponent from 'src/hooks/useHighOrderComponent';
import ConfirmPassword from 'src/components/ConfirmationByEmail/components/ConfirmPassword';
import ResetPassword from 'src/components/ConfirmationByEmail/components/ResetPassword';
import ConfirmNewUser from 'src/components/ConfirmationByEmail/components/NewUser';
import NewPassword from 'src/components/ConfirmationByEmail/components/NewPassword';
import Certificate from 'src/components/Talents/Certificate';
import CertificatePreview from 'src/components/Talents/Certificate/components/Preview';
import CertificateForm from 'src/components/Talents/Certificate/components/Form';
import Availability from 'src/components/Talents/Availability';
import AvailabilityForm from 'src/components/Talents/Availability/components/Form';
import ExternalLinks from 'src/components/Talents/ExternalLinks';
import ExternalLinksForm from 'src/components/Talents/ExternalLinks/components/Form';
import Specialization from 'src/components/Talents/Specialization';
import FormWrapper from 'src/components/Talents/Specialization/components/FormWrapper';
import InviteUser from 'src/components/Talents/InviteUser';
import SentEmailConfirmation from 'src/components/SignUpForm/components/SentEmailConfirmation';
import Overview from 'src/components/Talents/Overview';
import Documents from 'src/components/Talents/Documents';
import CreateCommentForm from 'src/components/Talents/Comments';
import WorkExperience from 'src/components/Talents/WorkExperience';
import WorkExperienceForm from 'src/components/Talents/WorkExperience/components/Form';
import CreateOrganizationForm from 'src/components/CreateOrganizationForm';
import GroupForm from 'src/components/GroupForm';
import CreateSubgroupForm from 'src/components/CreateSubgroupForm';
import CreateOrganizationUserForm from 'src/components/CreateOrganizationUserForm';
import ToggleOrgUserActivationForm from 'src/components/ToggleOrgUserActivationForm';
import ToggleTalentActivationForm from 'src/components/ToggleTalentActivationForm';
import ToggleOrgUserTSApprovalForm from 'src/components/ToggleOrgUserTSApprovalForm';
import ContactInfoForm from 'src/components/ContactInfoForm';
import InviteToGroupForm from 'src/components/InviteToGroupForm';
import SpecializationSelectForm from 'src/components/SpecializationSelectForm';
import SelectTalent from 'src/components/SelectTalent';
import ChangeRateForm from 'src/components/ChangeRateForm';
import CreateLibraryDocumentForm from 'src/components/CreateLibraryDocumentForm';
import UploadEngagementRequiredDocumentForm from 'src/components/UploadEngagementRequiredDocumentForm';
import ShareGroupForm from 'src/components/ShareGroupForm';
import CreateDepartmentForm from 'src/components/CreateDepartmentForm';
import ManageSpecializationsForm from 'src/components/ManageSpecializationsForm';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import SaveResourcesSearchForm from 'src/components/SaveResourcesSearchForm';
import DeclineTimeSheetForm from 'src/components/DeclineTimeSheetForm';
import TalentNotFoundFeedbackForm from 'src/components/TalentNotFoundFeedbackForm';
import PhoneNumberForm from 'src/components/PhoneNumberForm';
import CreatePurchaseOrderForm from 'src/components/CreatePurchaseOrderForm';
import CreateTimeSheetEntryForm from 'src/components/CreateTimeSheetEntryForm';
import CreateTimeSheetAdjustmentForm from 'src/components/CreateTimeSheetAdjustmentForm';
import KnowledgeCenterFAQFeedbackForm from 'src/components/KnowledgeCenterFAQFeedbackForm';
import TalentOpportunityApplyForm from 'src/features/opportunities/talentOpportunities/components';
import { EngagementTermsFormModal } from 'src/components/EngagementTermsForm';
import AppliedMessageModalContent from 'src/features/opportunities/details/components/ApplicantsTable/components/AppliedMessageModalContent';
import ApplicantQualificationReviewModal from 'src/components/ApplicantQualificationReviewModal';
import { TimeTrackingBulkApproveModalContent } from 'src/pages/ManagerTimetracking/components/TimeTrackingModalContent/TimeTrackingBulkApproveModalContent';

import CloseButton from './components/CloseButton';
import styles from './styles';

const ModalList: Record<ModalType, React.FC> = {
  [ModalType.SentEmailConfirmation]: props => useHighOrderComponent({ Component: SentEmailConfirmation, props }),
  [ModalType.ConfirmNewUser]: props => useHighOrderComponent({ Component: ConfirmNewUser, props }),
  [ModalType.NewPassword]: props => useHighOrderComponent({ Component: NewPassword, props }),
  [ModalType.ConfirmPassword]: props => useHighOrderComponent({ Component: ConfirmPassword, props }),
  [ModalType.ResetPassword]: props => useHighOrderComponent({ Component: ResetPassword, props }),
  [ModalType.TalentOverview]: () => useHighOrderComponent({ Component: Overview }),
  [ModalType.TalentDocuments]: props => useHighOrderComponent({ Component: Documents, props }),
  [ModalType.TalentProfileCommentsForm]: props => useHighOrderComponent({ Component: CreateCommentForm, props }),
  [ModalType.TalentWorkExperience]: () => useHighOrderComponent({ Component: WorkExperience }),
  [ModalType.TalentWorkExperienceForm]: props => useHighOrderComponent({ Component: WorkExperienceForm, props }),
  [ModalType.TalentExternalLinks]: () => useHighOrderComponent({ Component: ExternalLinks }),
  [ModalType.TalentExternalLinksForm]: props => useHighOrderComponent({ Component: ExternalLinksForm, props }),
  [ModalType.TalentSpecialization]: props => useHighOrderComponent({ Component: Specialization, props }),
  [ModalType.TalentSpecializationForm]: props => useHighOrderComponent({ Component: FormWrapper, props }),
  [ModalType.TalentAvailability]: () => useHighOrderComponent({ Component: Availability }),
  [ModalType.TalentAvailabilityForm]: props => useHighOrderComponent({ Component: AvailabilityForm, props }),
  [ModalType.TalentCertificate]: () => useHighOrderComponent({ Component: Certificate }),
  [ModalType.TalentCertificatePreview]: props => useHighOrderComponent({ Component: CertificatePreview, props }),
  [ModalType.TalentCertificateForm]: props => useHighOrderComponent({ Component: CertificateForm, props }),
  [ModalType.TalentAppliedMessage]: props => useHighOrderComponent({ Component: TalentOpportunityApplyForm, props }),
  [ModalType.InviteUser]: props => useHighOrderComponent({ Component: InviteUser, props }),
  [ModalType.CreateOrganizationForm]: props => useHighOrderComponent({ Component: CreateOrganizationForm, props }),
  [ModalType.GroupForm]: props => useHighOrderComponent({ Component: GroupForm, props }),
  [ModalType.CreateSubgroupForm]: props => useHighOrderComponent({ Component: CreateSubgroupForm, props }),
  [ModalType.CreateOrganizationUserForm]: props =>
    useHighOrderComponent({ Component: CreateOrganizationUserForm, props }),
  [ModalType.ToggleUserActivationForm]: props =>
    useHighOrderComponent({ Component: ToggleOrgUserActivationForm, props }),
  [ModalType.ToggleTalentActivationForm]: props =>
    useHighOrderComponent({ Component: ToggleTalentActivationForm, props }),
  [ModalType.ToggleUserTSApprovalForm]: props =>
    useHighOrderComponent({ Component: ToggleOrgUserTSApprovalForm, props }),
  [ModalType.ContactInfoForm]: props => useHighOrderComponent({ Component: ContactInfoForm, props }),
  [ModalType.InviteToGroupForm]: props => useHighOrderComponent({ Component: InviteToGroupForm, props }),
  [ModalType.SpecializationSelectForm]: props => useHighOrderComponent({ Component: SpecializationSelectForm, props }),
  [ModalType.SelectTalent]: props => useHighOrderComponent({ Component: SelectTalent, props }),
  [ModalType.ChangeRateForm]: props => useHighOrderComponent({ Component: ChangeRateForm, props }),
  [ModalType.CreateLibraryDocumentForm]: props =>
    useHighOrderComponent({
      Component: CreateLibraryDocumentForm,
      props,
    }),
  [ModalType.UploadEngagementRequiredDocumentForm]: props =>
    useHighOrderComponent({
      Component: UploadEngagementRequiredDocumentForm,
      props,
    }),
  [ModalType.ShareGroupForm]: props => useHighOrderComponent({ Component: ShareGroupForm, props }),
  [ModalType.CreateDepartmentForm]: props => useHighOrderComponent({ Component: CreateDepartmentForm, props }),
  [ModalType.ManageSpecializationsForm]: props =>
    useHighOrderComponent({
      Component: ManageSpecializationsForm,
      props,
    }),
  [ModalType.ConfirmationDialog]: props =>
    useHighOrderComponent({
      Component: ConfirmationDialog,
      props,
    }),
  [ModalType.SaveResourcesSearchForm]: props => useHighOrderComponent({ Component: SaveResourcesSearchForm, props }),
  [ModalType.DeclineTimeSheetForm]: props =>
    useHighOrderComponent({
      Component: DeclineTimeSheetForm,
      props,
    }),
  [ModalType.TalentNotFoundFeedbackForm]: props =>
    useHighOrderComponent({
      Component: TalentNotFoundFeedbackForm,
      props,
    }),
  [ModalType.PhoneNumberForm]: () => useHighOrderComponent({ Component: PhoneNumberForm }),
  [ModalType.CreatePurchaseOrderForm]: props => useHighOrderComponent({ Component: CreatePurchaseOrderForm, props }),
  [ModalType.CreateTimeSheetEntryForm]: props =>
    useHighOrderComponent({
      Component: CreateTimeSheetEntryForm,
      props,
    }),
  [ModalType.CreateTimeSheetAdjustmentForm]: props =>
    useHighOrderComponent({
      Component: CreateTimeSheetAdjustmentForm,
      props,
    }),
  [ModalType.KnowledgeCenterFAQFeedbackForm]: () =>
    useHighOrderComponent({
      Component: KnowledgeCenterFAQFeedbackForm,
    }),
  [ModalType.EngagementTermsForm]: props =>
    useHighOrderComponent({
      Component: EngagementTermsFormModal,
      props,
    }),
  [ModalType.AppliedMessage]: props =>
    useHighOrderComponent({
      Component: AppliedMessageModalContent,
      props,
    }),
  [ModalType.ApplicantQualificationReviewModal]: props =>
    useHighOrderComponent({
      Component: ApplicantQualificationReviewModal,
      props,
    }),
  [ModalType.TimeTrackingBulkApprove]: props =>
    useHighOrderComponent({
      Component: TimeTrackingBulkApproveModalContent,
      props,
    }),
};

const Modal: React.FC = () => {
  const { currentModal, hideModal, hideAllModals } = useModals();
  const { location } = useRouter();
  const { lessThanTablet } = useScreen();
  const dialogRef = useRef(null);

  const showInFullScreen = lessThanTablet;

  useEffect(() => {
    hideAllModals();
  }, [location]);

  if (!currentModal) {
    return null;
  }

  const {
    type,
    modalLayout = ModalLayout.default,
    isCloseButtonVisible = false,
    isCloseOnClickOutside = false,
    dialogSx,
    props = {},
  } = currentModal;
  const ModalContentComponent = ModalList[type];
  const currentModalLayout = styles[modalLayout];

  const onCloseOutside: MouseEventHandler<HTMLDivElement> = e => {
    const dialog = dialogRef?.current;
    const currentTarget = e.target;
    const clickedOnModal = dialog.contains(currentTarget);
    if (!clickedOnModal) {
      hideModal();
    }
  };

  return (
    <Dialog
      onClick={isCloseOnClickOutside ? onCloseOutside : () => null}
      open
      scroll={showInFullScreen ? 'paper' : 'body'}
      fullScreen={showInFullScreen}
      sx={[currentModalLayout, ...(Array.isArray(dialogSx) ? dialogSx : [dialogSx])]}
    >
      <DialogContent ref={dialogRef} sx={{ padding: { tablet: 0 } }}>
        <ModalContentComponent {...props} />
      </DialogContent>
      {isCloseButtonVisible && <CloseButton modalLayout={modalLayout} hideModal={hideModal} />}
    </Dialog>
  );
};

export default Modal;
