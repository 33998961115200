import { emptyApi } from 'src/services/emptyApi';
import { SingleResponse } from 'src/types/utils';
import apiRoutes from 'src/routes/apiRoutes';
import { TalentOpportunityApply, TalentOpportunityApplyBase } from 'src/features/opportunities/types';

const opportunityTalentApi = emptyApi.injectEndpoints({
  endpoints: builder => ({
    getOpportunityTalent: builder.query<SingleResponse<TalentOpportunityApplyBase>, ID>({
      query: id => {
        return {
          url: apiRoutes.orgSide.opportunityTalent(id),
          method: 'GET',
        };
      },
    }),
    createOpportunityTalent: builder.mutation<
      SingleResponse<TalentOpportunityApply>,
      { opportunityId: ID; talentId: ID; isInvited?: boolean; statusValue?: TalentOpportunityApply['statusValue'] }
    >({
      query: params => {
        return {
          url: apiRoutes.orgSide.opportunityTalent(),
          method: 'POST',
          body: params,
        };
      },
    }),
    patchOpportunityTalent: builder.mutation<
      SingleResponse<TalentOpportunityApply>,
      {
        applyId: ID;
        newApplyData: Partial<Pick<TalentOpportunityApply, 'statusValue' | 'managerComment'>>;
      }
    >({
      query: ({ applyId, newApplyData }) => {
        return {
          url: apiRoutes.orgSide.opportunityTalent(applyId),
          method: 'PATCH',
          body: newApplyData,
        };
      },
    }),
  }),
});

export const {
  useCreateOpportunityTalentMutation,
  usePatchOpportunityTalentMutation,
  useLazyGetOpportunityTalentQuery,
} = opportunityTalentApi;
