import { Box, Typography } from '@mui/material';
import React from 'react';

import TextEditor from 'src/components/TextEditor';
import { TalentOpportunityApplyInfo } from 'src/features/opportunities/types/talentOpportunityApply';
import { convertFromHtmlFormat } from 'src/utils/textEditor';

type TalentApplicationMessageProps = TalentOpportunityApplyInfo;

export const TalentApplicationMessage: React.FunctionComponent<TalentApplicationMessageProps> = ({
  skillsAndQualifications,
  experience,
  applyMessage,
}) => {
  return (
    <Box>
      {skillsAndQualifications && (
        <Box mb={2}>
          <Typography fontWeight="600" mb={1}>
            Skills and Qualifications
          </Typography>
          <TextEditor readOnly value={convertFromHtmlFormat(skillsAndQualifications)} />
        </Box>
      )}

      {experience && (
        <Box mb={2}>
          <Typography fontWeight="600" mb={1}>
            Experience
          </Typography>
          <TextEditor readOnly value={convertFromHtmlFormat(experience)} />
        </Box>
      )}

      {applyMessage && (
        <Box mb={3}>
          <Typography fontWeight="600" mb={1}>
            More Details
          </Typography>
          <TextEditor readOnly value={convertFromHtmlFormat(applyMessage)} />
        </Box>
      )}
    </Box>
  );
};
